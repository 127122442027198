import usePostUpsertUser from '@/src/common/hooks/usePostUpsertUser'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { useEffect, useRef } from 'react'
import EmailPassword from './EmailPassword'
import FacebookButton from './FacebookButton'
import { appleSignIn, facebookSignIn, googleSignIn } from './FirebaseAuth'
import GoogleButton from './GoogleButton'
import AppleButton from './AppleButton'
import {
    EMAIL_TOGGLE,
    EXISTING_LOGIN,
    NEW_LOGIN,
    PHONE_TOGGLE,
} from './LoginConstants'
import { useLoginContext } from './LoginContext'
import PhoneNumber from './PhoneNumber'
import {
    CorporateLogin,
    Disclosure,
    Line,
    PhoneAndEmailButtons,
    Separator,
    SocialButtons,
    StyledH6,
    ToggleButton,
} from './StyledComponents'
import { useCookies } from 'react-cookie'
import { IOS_PWA_APP_TYPE } from '@/src/common/constants'
import LoginFormBaseLayout from '@/src/modules/login/LoginFormBaseLayout'

type TLoginProps = {
    showCorporateLogin: boolean
}

const LoginForm = ({ showCorporateLogin = false }: TLoginProps) => {
    const {
        actionText,
        loginType,
        setLoginType,
        emailPhoneToggle,
        setEmailPhoneToggle,
        showVerificationScreen,
        setShowVerificationScreen,
        showResetPassword,
        email,
        phoneNumber,
        setEmail,
        setPhoneNumber,
    } = useLoginContext()

    const { user, refreshUser, isVerified } = useUser()
    const { mutate: upsertUser } = usePostUpsertUser()
    const timer = useRef<any>()

    const [cookies] = useCookies()
    const appType = cookies?.app_type

    const isNewLogin: boolean = loginType === NEW_LOGIN

    function clearExistingValues() {
        email && setEmail('')
        phoneNumber && setPhoneNumber('')
    }

    useEffect(() => {
        if (isVerified) {
            upsertUser(null, {
                onSuccess: () => {},
                onError: (e) => console.log(e),
            })
        }

        if (user?.email && !user.emailVerified) {
            setEmailPhoneToggle(EMAIL_TOGGLE)
            setShowVerificationScreen(true)

            // check if user verifies email on a different device
            // while on the "we sent verification email" screen
            timer.current = setInterval(() => refreshUser(), 5000)
        }

        if (!user) {
            setEmailPhoneToggle(PHONE_TOGGLE)
            setShowVerificationScreen(false)
        }

        return () => clearInterval(timer.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVerified, user])

    if (showVerificationScreen || showResetPassword) {
        return (
            <LoginFormBaseLayout>
                {emailPhoneToggle === EMAIL_TOGGLE ? (
                    <EmailPassword />
                ) : (
                    <PhoneNumber />
                )}
            </LoginFormBaseLayout>
        )
    }

    return (
        <LoginFormBaseLayout>
            {/*if the app type is an iOS PWA then hide the social login buttons*/}
            {/*until we figure out to get it working in the iOS App Store app*/}
            {appType !== IOS_PWA_APP_TYPE && (
                <>
                    <SocialButtons>
                        <FacebookButton
                            label={`${actionText} with Facebook`}
                            onClick={facebookSignIn}
                        />
                        <GoogleButton
                            label={`${actionText} with Google`}
                            onClick={googleSignIn}
                        />
                        <AppleButton
                            label={`${actionText} with Apple`}
                            onClick={appleSignIn}
                        />
                    </SocialButtons>
                    <Separator>
                        <Line />
                        OR
                        <Line />
                    </Separator>
                </>
            )}
            <PhoneAndEmailButtons>
                <ToggleButton
                    shortLabel={`${actionText} via phone`}
                    longLabel={`${actionText} via phone`}
                    active={emailPhoneToggle === PHONE_TOGGLE}
                    onClick={() => {
                        setEmailPhoneToggle(PHONE_TOGGLE)
                        clearExistingValues()
                    }}
                />
                <ToggleButton
                    shortLabel={`${actionText} via email`}
                    longLabel={`${actionText} via email`}
                    active={emailPhoneToggle === EMAIL_TOGGLE}
                    onClick={() => {
                        setEmailPhoneToggle(EMAIL_TOGGLE)
                        clearExistingValues()
                    }}
                />
            </PhoneAndEmailButtons>
            {emailPhoneToggle === EMAIL_TOGGLE ? (
                <EmailPassword />
            ) : (
                <PhoneNumber />
            )}
            {/*if user is in new login flow, show option to sign up and vice versa*/}
            <StyledH6>
                {isNewLogin
                    ? 'Already have an account?'
                    : "Don't have an account?"}
                <button
                    onClick={() => {
                        setLoginType(isNewLogin ? EXISTING_LOGIN : NEW_LOGIN)
                        clearExistingValues()
                    }}
                >
                    {isNewLogin ? 'Log In' : 'Sign Up'}
                </button>
            </StyledH6>
            {showCorporateLogin && <CorporateLogin />}
            <Disclosure />
        </LoginFormBaseLayout>
    )
}

export default LoginForm
